import CareerService from "../service";
import { router } from "@/router";

export const actions = {
  listCareers: ({ commit, dispatch }, page) => {
    dispatch("enableLoading");

    CareerService.list()
      .then(res => {
        dispatch("disableLoading");
        commit("setCareers", res.data);
      })
      .catch(e => {
        dispatch("disableLoading");
        dispatch("createGlobalMessage", {
          type: "error",
          message: e.response.data.message,
          timeout: 6000
        });
      });
  }
};

export default {};
