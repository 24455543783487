export const mutations = {
  setModalViewProduct(state, payload) {
    state.modalViewProduct = payload;
  },
  setProducts(state, payload) {
    state.products = payload;
  },
  setProduct(state, payload) {
    state.product = payload;
  },
  setMarketProducts(state, payload) {
    state.markedProducts = payload;
  }
};

export default {};
