export const career_routes = [
  {
    path: "/careers",
    component: () => import("../Main.vue"),
    name: "Planos de Ativação"
  },
  {
    path: "/premiacoes",
    component: () => import("../MainAward.vue"),
    name: "Premiações"
  },
];

export default {};
