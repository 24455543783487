export const mutations = {
  setModalViewPurchase(state, payload) {
    state.modalViewPurchase = payload;
  },
  setModalViewPurchaseActive(state, payload) {
    state.modalViewPurchaseActive = payload;
  },
  setPurchases(state, payload) {
    state.purchases = payload;
  },
  setPurchase(state, payload) {
    state.purchase = payload;
  }
};

export default {};
