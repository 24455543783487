export const mutations = {
  setBalanceInvestment(state, payload) {
    state.balanceInvestment = payload
  },
  setBalanceToken(state, payload) {
    state.balanceToken = payload
  },
  setBalanceUnilevel(state, payload) {
    state.balanceUnilevel = payload
  },
  setUserRanking(state, payload) {
    state.userRanking = payload
  },
  setRequestPayments(state, payload) {
    state.requestPayments = payload
  },
  setRequestPayment(state, payload) {
    state.requestPayment = payload
  },
  setMonthlyPayments(state, payload) {
    state.monthlyPayments = payload
  },
  setNextMonthlyPayment(state, payload) {
    state.nextMonthlyPayment = payload
  },
  setTransferBalance(state, payload) {
    state.transferBalance = payload
  },
  setAccountBanks(state, payload) {
    state.accountBanks = payload
  },
  setAccountBank(state, payload) {
    state.accountBank = payload
  },
  setModalViewAccountBank(state, payload) {
    state.modalViewAccountBank = payload
  }
};

export default {};
