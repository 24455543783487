export const mutations = {
  setModalViewPaymentMethod(state, payload) {
    state.modalViewPaymentMethod = payload;
  },
  setPaymentMethods(state, payload) {
    state.paymentMethods = payload;
  },
  setPaymentMethod(state, payload) {
    state.paymentMethod = payload;
  }
};

export default {};
