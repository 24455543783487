export const mutations = {
  setModalViewUnilevel(state, payload) {
    state.modalViewUnilevel = payload;
  },
  setUnilevels(state, payload) {
    state.unilevels = payload;
  },
  setUnilevel(state, payload) {
    state.unilevel = payload;
  }
};

export default {};
