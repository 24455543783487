export const mutations = {
  setModalDiscountCoupon(state, payload) {
    state.modalDiscountCoupon = payload;
  },
  setDiscountCoupons(state, payload) {
    state.discountCoupons = payload;
  },
  setDiscountCoupon(state, payload) {
    state.discountCoupon = payload;
  }
};

export default {};
