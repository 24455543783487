import TicketService from "../service";
import { router } from "@/router";

export const actions = {
  listTickets({ commit }) {
    TicketService.list()
      .then(res => {
        commit("disableLoading");
        commit("setTickets", res.data);
      })
      .catch(e => {
        commit("disableLoading");
        commit("createGlobalMessage", {
          type: "warning",
          message: e.responsa.data.message,
          timeout: 6000
        });
      });
  },
};

export default {};
