export const state = {
  modalViewPurchase: false,
  modalViewPurchaseActive: false,
  purchases: [],
  purchase: {
    purchase_type: "",
    monthly_payments: [],
    price: 0
  }
};

export default {};
