import http from "@/apps/shared/baseService/config";

class Service {
  list() {
    return http.post("/user/purchase/list");
  }
  createPurchase(payload) {
    return http.post("user/purchase/create-purchase", payload);
  }
  view(payload) {
    return http.post("user/purchase/view-purchase", payload);
  }
}

export default new Service();
