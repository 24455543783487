import UserService from "../service";
import moment from "moment";

export const actions = {
  listBalanceInvestment({ commit, dispatch }) {
    dispatch("enableLoading");
    UserService.listBalanceInvestment()
      .then(res => {
        dispatch("disableLoading");
        commit("setBalanceInvestment", res.data);
      })
      .catch(e => {
        dispatch("disableLoading");
        dispatch("createGlobalMessage", {
          type: "error",
          message: e.response.data.mesage,
          timeout: 6000
        });
      });
  },
  listBalanceToken({ commit, dispatch }) {
    dispatch("enableLoading");
    UserService.listBalanceToken()
      .then(res => {
        dispatch("disableLoading");
        commit("setBalanceToken", res.data);
      })
      .catch(e => {
        dispatch("disableLoading");
        dispatch("createGlobalMessage", {
          type: "error",
          message: e.response.data.mesage,
          timeout: 6000
        });
      });
  },
  listBalanceUnilevel({ commit, dispatch }) {
    dispatch("enableLoading");
    UserService.listBalanceUnilevel()
      .then(res => {
        dispatch("disableLoading");
        commit("setBalanceUnilevel", res.data);
      })
      .catch(e => {
        dispatch("disableLoading");
        dispatch("createGlobalMessage", {
          type: "error",
          message: e.response.data.mesage,
          timeout: 6000
        });
      });
  },
  listRanking({ commit, dispatch }) {
    dispatch("enableLoading");
    UserService.listRanking()
      .then(res => {
        dispatch("disableLoading");
        commit("setUserRanking", res.data);
      })
      .catch(e => {
        dispatch("disableLoading");
        dispatch("createGlobalMessage", {
          type: "error",
          message: e.response.data.mesage,
          timeout: 6000
        });
      });
  },
  updateProfile({ commit, dispatch, getters }) {
    dispatch("enableLoading");
    UserService.updateProfile(getters.getLoggedUser)
      .then(res => {
        dispatch("disableLoading");
        localStorage.user = JSON.stringify(res.data);
        dispatch("createGlobalMessage", {
          type: "success",
          message: "Perfil atualizado",
          timeout: 6000
        });
      })
      .catch(e => {
        dispatch("disableLoading");
        dispatch("createGlobalMessage", {
          type: "error",
          message: e.response.data.mesage,
          timeout: 6000
        });
      });
  },
  updateAutimaticMonthlyPayment({ dispatch, commit }, payload) {
    dispatch("enableLoading");
    UserService.updateProfile({ automatic_monthly_payment: payload })
      .then(res => {
        dispatch("disableLoading");
        localStorage.user = JSON.stringify(res.data);
        dispatch("createGlobalMessage", {
          type: "success",
          message: `Status atualizado: ${
            payload ? "Pagamento Automático" : "Pagamento Manual"
          }`,
          timeout: 6000
        });
      })
      .catch(e => {
        dispatch("disableLoading");
        dispatch("createGlobalMessage", {
          type: "error",
          message: e.response.data.mesage,
          timeout: 6000
        });
      });
  },
  listRequestPayment({ commit, dispatch, getters }) {
    dispatch("enableLoading");
    UserService.listRequestPayment()
      .then(res => {
        dispatch("disableLoading");
        commit("setRequestPayments", res.data);
      })
      .catch(e => {
        dispatch("disableLoading");
        dispatch("createGlobalMessage", {
          type: "error",
          message: e.response.data.mesage,
          timeout: 6000
        });
      });
  },
  requestPayment({ commit, dispatch, getters }, payload) {
    return new Promise((resolve, reject) => {
      dispatch("enableLoading");
      UserService.requestPayment(payload || getters.getRequestPayment)
        .then(() => {
          dispatch("createGlobalMessage", {
            type: "success",
            message: "Pedido de saque enviado",
            timeout: 4000
          });
          // dispatch("listRequestPayment");
          commit("setRequestPayment", {
            payment_method: {}
          });
          dispatch("disableLoading");
          resolve();
        })
        .catch(e => {
          dispatch("disableLoading");
          dispatch("createGlobalMessage", {
            type: "error",
            message: e.response.data.mesage,
            timeout: 6000
          });
          dispatch("disableLoading");
          reject(e);
        });
    });
  },
  transferBalance({ dispatch }, payload) {
    return new Promise((resolve, reject) => {
      dispatch("enableLoading");
      UserService.transferBalance(payload)
        .then(res => {
          dispatch("disableLoading");
          dispatch("createGlobalMessage", {
            type: "success",
            message: "Transferência Concluída",
            timeout: 6000
          });
          resolve(res);
        })
        .catch(e => {
          dispatch("disableLoading");
          dispatch("createGlobalMessage", {
            type: "error",
            message: e.response.data.mesage,
            timeout: 6000
          });
          reject(e);
        });
    });
  },
  requestDeposit({ dispatch }, payload) {
    return new Promise((resolve, reject) => {
      dispatch("enableLoading");
      UserService.requestDeposit(payload)
        .then(res => {
          dispatch("disableLoading");
          resolve(res);
        })
        .catch(e => {
          dispatch("disableLoading");
          reject(e);
        });
    });
  },
  getDestinationUser(context, payload) {
    return new Promise((resolve, reject) => {
      UserService.getDestinationUser(payload)
        .then(res => {
          resolve(res.data);
        })
        .catch(e => {
          context.dispatch("createGlobalMessage", {
            type: "error",
            message: e.response.data.mesage,
            timeout: 6000
          });
          reject(e);
        });
    })
  },
  createRequestPromise(context, payload) {
    return new Promise((resolve, reject) => {
      UserService.createRequestPromise(payload)
        .then(res => {
          resolve(res.data);
        })
        .catch(e => {
          context.dispatch("createGlobalMessage", {
            type: "error",
            message: e.response.data.mesage,
            timeout: 6000
          });
          reject(e);
        });
    })
  },
  approveRequestPromise(context, payload) {
    return new Promise((resolve, reject) => {
      UserService.approveRequestPromise(payload)
        .then(res => {
          resolve(res.data);
        })
        .catch(e => {
          // context.dispatch("createGlobalMessage", {
          //   type: "error",
          //   message: e.response.data.mesage,
          //   timeout: 6000
          // });
          reject(e);
        });
    })
  },
  generateCode() {
    return new Promise((resolve, reject) => {
      UserService.generateCode()
        .then(res => {
          resolve(res.data);
        })
        .catch(e => {
          reject(e);
        });
    });
  },
  listMonthlyPayment({ commit, dispatch }) {
    dispatch("enableLoading");
    UserService.listMonthlyPayment()
      .then(res => {
        dispatch("disableLoading");
        if (res.data.length > 0) {
          commit(
            "setNextMonthlyPayment",
            moment(res.data[0].due_date).format("DD/MM/YYYY")
          );
        }
        commit("setMonthlyPayments", res.data);
      })
      .catch(e => {
        dispatch("disableLoading");
        dispatch("createGlobalMessage", {
          type: "error",
          message: e.response.data.mesage,
          timeout: 6000
        });
      });
  },
  listAccountBanks({ commit, dispatch, getters }) {
    dispatch("enableLoading");
    UserService.listMyAccountBanks()
      .then(res => {
        dispatch("disableLoading");
        commit("setAccountBanks", res.data);
      })
      .catch(e => {
        dispatch("disableLoading");
        dispatch("createGlobalMessage", {
          type: "error",
          message: e.response.data.mesage,
          timeout: 6000
        });
      });
  },
  openModalViewAccountBank(state, payload) {
    if (payload) {
      state.commit("setAccountBank", payload);
    } else {
      state.commit("setAccountBank", {});
    }
    state.commit("setModalViewAccountBank", true);
  },
  closeModalViewAccountBank(state) {
    state.commit("setAccountBank", {});
    state.commit("setModalViewAccountBank", false);
  },
  createAccountBank({ dispatch, commit, getters }) {
    dispatch("enableLoading");
    UserService.createAccountBank(getters.getAccountBank)
      .then(() => {
        dispatch("disableLoading");
        commit("setAccountBank", {});
        commit("setModalViewAccountBank", false);
        dispatch("createGlobalMessage", {
          type: "success",
          message: `Registro Concluído!`,
          timeout: 6000
        });
      })
      .catch(e => {
        dispatch("disableLoading");
        dispatch("createGlobalMessage", {
          type: "error",
          message: e.response.data.mesage,
          timeout: 6000
        });
      });
  },
  updateAccountBank({ dispatch, getters }) {
    dispatch("enableLoading");
    UserService.updateAccountBank(getters.getAccountBank)
      .then(() => {
        dispatch("disableLoading");
        dispatch("createGlobalMessage", {
          type: "success",
          message: `Registro Concluído!`,
          timeout: 6000
        });
      })
      .catch(e => {
        dispatch("disableLoading");
        dispatch("createGlobalMessage", {
          type: "error",
          message: e.response.data.mesage,
          timeout: 6000
        });
      });
  },
  getBalancePurchaseInvestment({ dispatch, getters, commit }) {
    dispatch("enableLoading");
    UserService.getBalancePurchaseInvestment(getters.getRequestPayment)
      .then(res => {
        dispatch("disableLoading");
        commit("setBalanceInvestment", res.data);
      })
      .catch(e => {
        dispatch("disableLoading");
        dispatch("createGlobalMessage", {
          type: "error",
          message: e.response.data.mesage,
          timeout: 6000
        });
      });
  }
};

export default {};
