import http from "@/apps/shared/baseService/config";

const base = {
  rota: "accountBank",
  nome: "Contas Bancárias",
  auth: "user"
};

export const service = {
  criar: data => http.post(`/${base.auth}/${base.rota}/create`, data),
  listar: data => http.post(`/${base.auth}/${base.rota}/list`, data),
  update: data => http.post(`/${base.auth}/${base.rota}/update-photo`, data),
  excluir: data => http.post(`/${base.auth}/${base.rota}/delete`, data),
  show_pinbank_account: data => http.post(`/${base.auth}/${base.rota}/show-pinbank-account`, data)
};

const module = {
  actions: {
    list_accounts: ({ commit, dispatch, getters }) => {
      return new Promise((resolve, reject) => {
        if (localStorage.accounts) {
          commit("set_accounts", JSON.parse(localStorage.accounts));
        } else {
          dispatch("enableLoading");
        }
        service
          .listar(getters.get_accounts_filters)
          .then(res => {
            localStorage.accounts = JSON.stringify(res.data);
            dispatch("disableLoading");
            commit("set_accounts", res.data);
            resolve(res.data);
          })
          .catch(e => {
            dispatch("disableLoading");
            dispatch("createGlobalMessage", {
              type: "error",
              message: e.response.data.message,
              timeout: 6000
            });
            reject(e);
          });
      })
    },
    show_pinbank_account: ({ commit, dispatch, getters }, payload) => {
      return new Promise((resolve, reject) => {
        service
          .show_pinbank_account(payload)
          .then(res => {
            dispatch("disableLoading");
            commit("set_account", res.data);
            resolve(res.data);
          })
          .catch(e => {
            dispatch("disableLoading");
            dispatch("createGlobalMessage", {
              type: "error",
              message: e.response.data.message,
              timeout: 6000
            });
            reject(e);
          });
      });
    },
    update_account: ({ dispatch }, payload) => {
      return new Promise((resolve, reject) => {
        dispatch("enableLoading");
        service
          .update(payload)
          .then(res => {
            dispatch("disableLoading");
            dispatch("createGlobalMessage", {
              type: "success",
              message: "Atualização Concluída!",
              timeout: 6000
            });
            resolve(res.data);
          })
          .catch(e => {
            dispatch("disableLoading");
            dispatch("createGlobalMessage", {
              type: "error",
              message: e.response.data.message,
              timeout: 6000
            });
            reject(e);
          });
      });
    },
    create_account: ({ dispatch }, payload) => {
      return new Promise((resolve, reject) => {
        dispatch("enableLoading");
        service
          .criar(payload)
          .then(res => {
            dispatch("disableLoading");
            dispatch("createGlobalMessage", {
              type: "success",
              message: "Registro Concluído!",
              timeout: 6000
            });
            resolve(res.data);
          })
          .catch(e => {
            dispatch("disableLoading");
            dispatch("createGlobalMessage", {
              type: "error",
              message: e.response.data.message,
              timeout: 6000
            });
            reject(e);
          });
      });
    }
  },
  state: {
    account: {},
    accounts: [],
    accounts_filters: {
      limitPerPage: 10,
      page: 1
    }
  },
  mutations: {
    set_account: (state, payload) => (state.account = payload),
    set_accounts: (state, payload) => (state.accounts = payload)
  },
  getters: {
    get_account: state => state.account,
    get_accounts: state => state.accounts,
    get_accounts_filters: state => state.accounts_filters
  }
};

const routes = [
  {
    path: `/${base.rota}`,
    component: () => import("./views/Main.vue"),
    name: base.nome
  }
];

export default {
  service,
  module,
  routes
};
