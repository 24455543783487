export const mutations = {
  setModalViewInvestment(state, payload) {
    state.modalViewInvestment = payload;
  },
  setInvestments(state, payload) {
    state.investments = payload;
  },
  setInvestment(state, payload) {
    state.investment = payload;
  }
};

export default {};
