export const mutations = {
  setModalViewAddress(state, payload) {
    state.modalViewAddress = payload;
  },
  setAddresses(state, payload) {
    state.addresses = payload;
  },
  setAddress(state, payload) {
    state.address = payload;
  }
};

export default {};
