import http from "@/apps/shared/baseService/config";
import axios from 'axios'

class Service {
  list() {
    return http.post("/user/address/list");
  }
  getCep(cep) {
    return axios.get(`https://viacep.com.br/ws/${cep}/json/`)
  }
  createAddress(payload) {
    return http.post("/user/address/create", payload)
  }
  update(payload) {
    return http.post("/user/address/update/"+payload._id, payload)
  }
}

export default new Service();
