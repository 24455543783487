export const logged_user_routes = [
  {
    path: "/logged-user",
    component: () => import("../Main.vue"),
    name: "Meu Perfil"
  },
  {
    path: "/config",
    component: () => import("../Config.vue"),
    name: "Configuração"
  },
  // {
  //   path: "/request-payment",
  //   component: () => import("../RequestPayment.vue"),
  //   name: "Saque"
  // },
  {
    path: "/ranking",
    component: () => import("../Ranking.vue"),
    name: "Ranking"
  },
  {
    path: "/transferencia/:transaction_type",
    props: true,
    component: () => import("../TransferBalance.vue"),
    name: "Transferência"
  },
  {
    path: "/solicitar-pagamento/:transaction_type",
    props: true,
    component: () => import("../RequestPay.vue"),
    name: "Solicitar Pagamento"
  },
  {
    path: "/pagar/:transaction_type",
    props: true,
    component: () => import("../GenerateCode.vue"),
    name: "Gerar Senha"
  },
  {
    path: "/saque/:transaction_type",
    props: true,
    component: () => import("../Withdraw.vue"),
    name: "Sacar"
  },
  {
    path: "/deposito/:transaction_type",
    props: true,
    component: () => import("../Deposit.vue"),
    name: "Depositar"
  },
];

export default {};
