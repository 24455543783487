import http from "@/apps/shared/baseService/config";

const base = {
  rota: "notifications",
  nome: "notifications",
  auth: "user"
};

export const service = {
  criar: data => http.post(`/${base.auth}/${base.rota}/create`, data),
  abrir: data => http.post(`/${base.auth}/${base.rota}/open-notify`, data),
  listar: data => http.post(`/${base.auth}/${base.rota}/list`, data),
  atualizar: data => http.post(`/${base.auth}/${base.rota}/update`, data),
  excluir: data => http.post(`/${base.auth}/${base.rota}/delete`, data),
  excluir_muitos: data =>
    http.post(`/${base.auth}/${base.rota}/delete-many`, data)
};

const module = {
  actions: {
    list_notifications: ({ commit, dispatch, getters }) => {
      dispatch("enableLoading");
      service
        .listar(getters.get_notifications_filtros)
        .then(res => {
          dispatch("disableLoading");
          commit("set_notifications", res.data);
        })
        .catch(e => {
          dispatch("disableLoading");
          dispatch("createGlobalMessage", {
            type: "error",
            message: e.response.data.message,
            timeout: 6000
          });
        });
    },
    atualizar_notification: ({ commit, dispatch, getters }) => {
      dispatch("enableLoading");
      service
        .atualizar({
          ...getters.get_notification,
          imagens: getters.getLinks,
          links: getters.getFiles
        })
        .then(res => {
          dispatch("disableLoading");
          commit("set_notification", res.data);
          dispatch("createGlobalMessage", {
            type: "success",
            message: "Atualização Concluída!",
            timeout: 6000
          });
          dispatch("listar_notifications");
        })
        .catch(e => {
          dispatch("disableLoading");
          dispatch("createGlobalMessage", {
            type: "error",
            message: e.response.data.message,
            timeout: 6000
          });
        });
    },
    criar_notification: ({ commit, dispatch, getters }) => {
      dispatch("enableLoading");
      service
        .criar({
          ...getters.get_notification,
          imagens: getters.getLinks,
          links: getters.getFiles
        })
        .then(() => {
          dispatch("disableLoading");
          commit("set_notification", {});
          commit("setLinks", []);
          dispatch("createGlobalMessage", {
            type: "success",
            message: "Registro Concluído!",
            timeout: 6000
          });
          dispatch("listar_notifications");
          commit("set_modal_view_notification", false);
        })
        .catch(e => {
          dispatch("disableLoading");
          dispatch("createGlobalMessage", {
            type: "error",
            message: e.response.data.message,
            timeout: 6000
          });
        });
    },
    excluir_notification: ({ dispatch, getters }, payload) => {
      dispatch("enableLoading");
      service
        .excluir(payload)
        .then(() => {
          dispatch("disableLoading");
          dispatch("createGlobalMessage", {
            type: "success",
            message: "Exclusão Concluída!",
            timeout: 6000
          });
          dispatch("listar_notifications");
        })
        .catch(e => {
          dispatch("disableLoading");
          dispatch("createGlobalMessage", {
            type: "error",
            message: e.response.data.message,
            timeout: 6000
          });
        });
    },
    excluir_notifications: ({ dispatch, getters }) => {
      dispatch("enableLoading");
      service
        .excluir(getters.get_notifications_marcados)
        .then(() => {
          dispatch("disableLoading");
          dispatch("createGlobalMessage", {
            type: "success",
            message: "Exclusão em massa Concluída!",
            timeout: 6000
          });
          dispatch("listar_notifications");
        })
        .catch(e => {
          dispatch("disableLoading");
          dispatch("createGlobalMessage", {
            type: "error",
            message: e.response.data.message,
            timeout: 6000
          });
        });
    },
    abre_modal_view_notification: async (
      { commit, getters, dispatch },
      payload
    ) => {
      if (payload) {
        commit("set_notification", payload);
        commit("setLinks", payload.imagens);
      }
      commit("set_modal_view_notification", true);
      await service.abrir(payload);
    },
    fecha_modal_view_notification: ({ commit }) => {
      commit("set_notification", {});
      commit("setLinks", []);
      commit("set_modal_view_notification", false);
    },
    add_notification_marcado: ({ commit, getters }, payload) => {
      commit("set_notifications_marcados", [
        ...getters.get_notifications_marcados,
        payload
      ]);
    },
    remove_notification_marcado: ({ commit, getters }, i) => {
      commit(
        "set_notifications_marcados",
        ...getters.get_notifications_marcados.filter(
          (item, index) => index !== i
        )
      );
    }
  },
  state: {
    notification: {},
    notifications: {
      docs: [],
      page: 1,
      totalPages: 1
    },
    notifications_marcados: [],
    notifications_filtros: {
      limitPerPage: 10,
      page: 1,
      dataInicio: "",
      dataFim: ""
    },
    modal_view_notification: false
  },
  mutations: {
    set_notification: (state, payload) => (state.notification = payload),
    set_notifications: (state, payload) => (state.notifications = payload),
    set_notifications_marcados: (state, payload) =>
      (state.notifications_marcados = payload),
    set_modal_view_notification: (state, payload) =>
      (state.modal_view_notification = payload)
  },
  getters: {
    get_notification: state => state.notification,
    get_notifications: state => state.notifications,
    get_notifications_marcados: state => state.notifications_marcados,
    get_modal_view_notification: state => state.modal_view_notification,
    get_notifications_filtros: state => state.notifications_filtros
  }
};

const routes = [
  {
    path: `/${base.rota}`,
    component: () => import("./views/Listar.vue"),
    name: base.nome
  }
];

export default {
  service,
  module,
  routes
};
