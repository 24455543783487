export const state = {
  modalViewUnilevel: false,
  unilevels: {
    level_1: [],
    level_2: [],
    level_3: [],
    level_4: []
  },
  unilevel: {
    level_1: [],
    level_2: [],
    level_3: [],
    level_4: []
  }
};

export default {};
