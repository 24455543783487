import UnilevelService from "../service";
import { router } from "@/router";

export const actions = {
  listUnilevels: ({ commit, dispatch }, data) => {
    dispatch("enableLoading");

    UnilevelService.list()
      .then(res => {
        dispatch("disableLoading");
        commit("setUnilevels", res.data);
      })
      .catch(e => {
        dispatch("disableLoading");
        dispatch("createGlobalMessage", {
          type: "error",
          message: e.response.data.message,
          timeout: 6000
        });
      });
  },

  viewUnilevel: ({ commit }, payload) => {
    commit("setUnilevel", payload);
    commit("setModalViewUnilevel", true);
  }
};

export default {};
