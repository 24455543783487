<template>
  <!-- SNACKBAR DE CONFIRMAÇÃO GLOBAL -->
  <v-dialog
    width="300"
    persistent
    v-model="getModalConfirmAction.opened"
    transition="slide-x-transition"
  >
    <v-card dark class="card">
      <div class="meio">
        <v-flex xs12>
          <div class="expande-horizontal centraliza">
            <v-avatar class="elevation-1" :color="$theme.primary">
              <v-icon class="fadeIn" color="grey darken-3">
                {{ getModalConfirmAction.icon }}
              </v-icon>
            </v-avatar>
          </div>
        </v-flex>

        <v-flex class="mt-3 mb-3" xs12>
          <div class="expande-horizontal centraliza">
            <v-spacer></v-spacer>

            <span class="orange--text fonte">
              {{ getModalConfirmAction.message }}
            </span>

            <v-spacer></v-spacer>
          </div>
        </v-flex>

        <v-flex xs12>
          <div class="expande-horizontal semquebra">
            <v-flex class="pa-2" xs6>
              <v-btn
                class="fonte"
                text
                color="default"
                block
                @click.prevent="unsetConfirmAction"
              >
                Não
              </v-btn>
            </v-flex>

            <v-flex class="pa-2" xs6>
              <v-btn
                class="fonte"
                :color="$theme.primary"
                block
                @click.prevent="sim()"
                outlined
              >
                Sim
              </v-btn>
            </v-flex>
          </div>
        </v-flex>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["getModalConfirmAction"])
  },
  methods: {
    ...mapActions(["unsetConfirmAction"]),
    sim() {
      this.$store.dispatch(
        this.getModalConfirmAction.action,
        this.getModalConfirmAction.action_value
      );
      this.unsetConfirmAction();
    }
  }
};
</script>

<style>
.card {
  padding: 12px;
  width: 300px;
  overflow: hidden;
  border-radius: 10px;
}
.expande-horizontal {
  display: flex;
  width: 100%;
}
</style>
