export const state = {
  balanceUnilevel: {
    extracts: [],
    balance: 0,
  },
  balanceInvestment: {
    extracts: [],
    balance: 0,
  },
  balanceToken: {
    extracts: [],
    balance: 0,
  },
  userRanking: {
    "docs": [],
    "totalDocs": 5,
    "limit": 10,
    "totalPages": 1,
    "page": 1,
    "pagingCounter": 1,
    "hasPrevPage": false,
    "hasNextPage": false,
    "prevPage": null,
    "nextPage": null
  },
  requestPayment: {
    tipo_saque: 'unilevel',
    payment_method: {},
    purchase: {}
  },
  requestPayments: [],
  monthlyPayments: [],
  nextMonthlyPayment: 'inativo',
  transferBalance: {},
  accountBank: {},
  accountBanks: [],
  modalViewAccountBank: false
};

export default {};
