export const mutations = {
  setModalViewTicket(state, payload) {
    state.modalViewTicket = payload;
  },
  setTickets(state, payload) {
    state.tickets = payload;
  },
  setTicket(state, payload) {
    state.ticket = payload;
  }
};

export default {};
