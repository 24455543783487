export const mutations = {
  setModalViewCareer(state, payload) {
    state.modalViewCareer = payload;
  },
  setCareers(state, payload) {
    state.careers = payload;
  },
  setCareer(state, payload) {
    state.career = payload;
  }
};

export default {};
